import { Klass } from '$vue/lib/transforms'

export default class Workathon {
  id: number
  assigner_name: string
  clazz_fullname: string
  published_on: string
  homework_papers_count: number

  @Klass(Date)
  published_at: Date

  @Klass(Date)
  deadline_at: Date

  subject_id: number
  stage_text: string
  clazz_id: number
  remark: string
}
