export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/index.vue'),
    /* no children */
  },
  {
    path: '/error',
    name: '/error',
    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/error.vue'),
    /* no children */
    meta: {
      "auth": false
    }
  },
  {
    path: '/joint_evaluations',
    /* internal name: '/joint_evaluations' */
    /* no component */
    children: [
      {
        path: '',
        name: '/joint_evaluations/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/joint_evaluations/index.vue'),
        /* no children */
      },
      {
        path: ':evaluation_id',
        /* internal name: '/joint_evaluations/[evaluation_id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/joint_evaluations/[evaluation_id]/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/joint_evaluations/[evaluation_id]/index.vue'),
            /* no children */
          },
          {
            path: 'batch_assign_homeworks',
            /* internal name: '/joint_evaluations/[evaluation_id]/batch_assign_homeworks' */
            /* no component */
            children: [
              {
                path: '',
                name: '/joint_evaluations/[evaluation_id]/batch_assign_homeworks/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/index.vue'),
                /* no children */
              },
              {
                path: ':batch_assign_homework_id',
                /* internal name: '/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'edit',
                    name: '/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/edit.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'new',
                name: '/joint_evaluations/[evaluation_id]/batch_assign_homeworks/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'homeworks',
            /* internal name: '/joint_evaluations/[evaluation_id]/homeworks' */
            /* no component */
            children: [
              {
                path: '',
                name: '/joint_evaluations/[evaluation_id]/homeworks/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/joint_evaluations/[evaluation_id]/homeworks/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'joint_evaluation_courseware_relations',
            /* internal name: '/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations' */
            /* no component */
            children: [
              {
                path: '',
                name: '/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/index.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/new.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: ':evaluation_id/edit',
        name: '/joint_evaluations/[evaluation_id].edit',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/joint_evaluations/[evaluation_id].edit.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/joint_evaluations/new',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/joint_evaluations/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/login.vue'),
    /* no children */
    meta: {
      "auth": false
    }
  },
  {
    path: '/orders',
    /* internal name: '/orders' */
    /* no component */
    children: [
      {
        path: '',
        name: '/orders/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/orders/index.vue'),
        /* no children */
      },
      {
        path: 'details',
        name: '/orders/details',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/orders/details.vue'),
        /* no children */
      },
      {
        path: 'summary',
        name: '/orders/summary',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/orders/summary.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/profile',
    /* internal name: '/profile' */
    /* no component */
    children: [
      {
        path: 'balance_records',
        /* internal name: '/profile/balance_records' */
        /* no component */
        children: [
          {
            path: '',
            name: '/profile/balance_records/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/profile/balance_records/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'password',
        /* internal name: '/profile/password' */
        /* no component */
        children: [
          {
            path: '',
            name: '/profile/password/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/profile/password/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'point_records',
        /* internal name: '/profile/point_records' */
        /* no component */
        children: [
          {
            path: '',
            name: '/profile/point_records/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/profile/point_records/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'purchases',
        /* internal name: '/profile/purchases' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/profile/purchases/[id]' */
            /* no component */
            children: [
              {
                path: 'success',
                name: '/profile/purchases/[id]/success',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/profile/purchases/[id]/success.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'new',
            name: '/profile/purchases/new',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/profile/purchases/new.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/school_income_stats',
    /* internal name: '/school_income_stats' */
    /* no component */
    children: [
      {
        path: '',
        name: '/school_income_stats/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/school_income_stats/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/schools',
    /* internal name: '/schools' */
    /* no component */
    children: [
      {
        path: '',
        name: '/schools/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/index.vue'),
        /* no children */
      },
      {
        path: ':school_id',
        /* internal name: '/schools/[school_id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/schools/[school_id]/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/index.vue'),
            /* no children */
          },
          {
            path: 'batch_workathons',
            /* internal name: '/schools/[school_id]/batch_workathons' */
            /* no component */
            children: [
              {
                path: 'new',
                name: '/schools/[school_id]/batch_workathons/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/batch_workathons/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'clazzes',
            /* internal name: '/schools/[school_id]/clazzes' */
            /* no component */
            children: [
              {
                path: '',
                name: '/schools/[school_id]/clazzes/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/index.vue'),
                /* no children */
              },
              {
                path: ':clazz_id',
                /* internal name: '/schools/[school_id]/clazzes/[clazz_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/schools/[school_id]/clazzes/[clazz_id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/[clazz_id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'agent_transaction_records',
                    /* internal name: '/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records' */
                    /* no component */
                    children: [
                      {
                        path: ':agent_transaction_record_id',
                        /* internal name: '/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]/index.vue'),
                            /* no children */
                          },
                          {
                            path: 'form',
                            name: '/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]/form',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]/form.vue'),
                            /* no children */
                          }
                        ],
                      }
                    ],
                  },
                  {
                    path: 'homeworks',
                    /* internal name: '/schools/[school_id]/clazzes/[clazz_id]/homeworks' */
                    /* no component */
                    children: [
                      {
                        path: ':homework_id',
                        /* internal name: '/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/index.vue'),
                            /* no children */
                          },
                          {
                            path: 'homework_papers',
                            /* internal name: '/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers' */
                            /* no component */
                            children: [
                              {
                                path: ':homework_paper_id',
                                /* internal name: '/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]' */
                                /* no component */
                                children: [
                                  {
                                    path: '',
                                    name: '/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/',
                                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/index.vue'),
                                    /* no children */
                                  }
                                ],
                              }
                            ],
                          }
                        ],
                      }
                    ],
                  },
                  {
                    path: 'students',
                    /* internal name: '/schools/[school_id]/clazzes/[clazz_id]/students' */
                    /* no component */
                    children: [
                      {
                        path: 'new',
                        name: '/schools/[school_id]/clazzes/[clazz_id]/students/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/[clazz_id]/students/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'studentships',
                    /* internal name: '/schools/[school_id]/clazzes/[clazz_id]/studentships' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/schools/[school_id]/clazzes/[clazz_id]/studentships/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/[clazz_id]/studentships/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/schools/[school_id]/clazzes/[clazz_id]/studentships/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/[clazz_id]/studentships/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'teachers',
                    /* internal name: '/schools/[school_id]/clazzes/[clazz_id]/teachers' */
                    /* no component */
                    children: [
                      {
                        path: 'new',
                        name: '/schools/[school_id]/clazzes/[clazz_id]/teachers/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/[clazz_id]/teachers/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'teacherships',
                    /* internal name: '/schools/[school_id]/clazzes/[clazz_id]/teacherships' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/schools/[school_id]/clazzes/[clazz_id]/teacherships/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/[clazz_id]/teacherships/index.vue'),
                        /* no children */
                      },
                      {
                        path: ':teachership_id',
                        /* internal name: '/schools/[school_id]/clazzes/[clazz_id]/teacherships/[teachership_id]' */
                        /* no component */
                        children: [
                          {
                            path: 'edit',
                            name: '/schools/[school_id]/clazzes/[clazz_id]/teacherships/[teachership_id]/edit',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/[clazz_id]/teacherships/[teachership_id]/edit.vue'),
                            /* no children */
                          }
                        ],
                      },
                      {
                        path: 'new',
                        name: '/schools/[school_id]/clazzes/[clazz_id]/teacherships/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/[clazz_id]/teacherships/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'workathons',
                    /* internal name: '/schools/[school_id]/clazzes/[clazz_id]/workathons' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/schools/[school_id]/clazzes/[clazz_id]/workathons/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/clazzes/[clazz_id]/workathons/index.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              }
            ],
          },
          {
            path: 'school_homework_reports',
            /* internal name: '/schools/[school_id]/school_homework_reports' */
            /* no component */
            children: [
              {
                path: '',
                name: '/schools/[school_id]/school_homework_reports/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/school_homework_reports/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'textbook_forms',
            /* internal name: '/schools/[school_id]/textbook_forms' */
            /* no component */
            children: [
              {
                path: '',
                name: '/schools/[school_id]/textbook_forms/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/textbook_forms/index.vue'),
                /* no children */
              },
              {
                path: ':textbook_form_id/edit',
                name: '/schools/[school_id]/textbook_forms/[textbook_form_id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/textbook_forms/[textbook_form_id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/schools/[school_id]/textbook_forms/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/textbook_forms/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'workathons',
            /* internal name: '/schools/[school_id]/workathons' */
            /* no component */
            children: [
              {
                path: '',
                name: '/schools/[school_id]/workathons/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/schools/[school_id]/workathons/index.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/students',
    /* internal name: '/students' */
    /* no component */
    children: [
      {
        path: '',
        name: '/students/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf/school/school/app/javascript/vue/pages/students/index.vue'),
        /* no children */
      }
    ],
  }
]
