import { Type } from "class-transformer"

export default class Homework {
  id: number
  workathon_id: number
  ownership: string
  standard: string
  exam_time: any
  allow_display_correct: boolean
  allow_published_score: string[]

  courseware_code: string
  courseware_id: number
  courseware_name: string
  courseware_token: string
  courseware_textbook_name: string
  courseware_type: "TestPaper" | "InteractiveReading" | "RepeatText" | "Cosplay" | "SpeechText"
  courseware_category_name: string
  stages: any[]

  unchecked_homework_papers_count!: number
  homework_papers_count!: number
  finished_count!: number
}
