import { ErrorAccessDenied, ErrorUnauthorized } from '$vue/lib/requests'
import { AxiosError } from 'axios'
import { ElNotification } from 'element-plus'
import { type App } from 'vue'

function handleError(err, app: App) {
  const router = app.config.globalProperties.router
  const store = app.config.globalProperties.$store
  store.commit('session/SET_ERROR', { error: err })

  if (err instanceof AxiosError) {
    ElNotification({
      title: err.name || 'Error',
      message: err.message || 'Unknown Error',
      type: 'error',
      offset: 50,
    })
  } else if (err instanceof ErrorAccessDenied) {
    router.push({
      path: "/error",
      query: {
        status: 403,
      },
    })
  } else if (err instanceof ErrorUnauthorized) {
    router.push({
      path: "/error",
      query: {
        status: 401,
      },
    })
  } else {
    // router.push({
    //   path: "/error",
    // })
    console.error(err)
  }
}

export default function(app: App) {
  const router = app.config.globalProperties.$router

  // router.onError((error) => {
  //   handleError(error, app)
  // })

  // app.config.errorHandler = (error, vm, info) => {
  //   handleError(error, app)
  // }
}
