import type { InjectionKey, Ref } from "vue"

export interface CardPreset {
  root: string
  header: string
  content: string
  footer: string
}

export const PresetKey: InjectionKey<Ref<CardPreset>> = Symbol('card-preset')
// export const VariantKey: InjectionKey<Ref<string>> = Symbol('card-variant')
