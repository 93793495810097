import { Account } from "$vue/models"
import BaseObjectRequest from "./BaseObjectRequest"

export default {
  Get: class extends BaseObjectRequest<Account> {
    constructor() {
      super()
      this.klass = Account
      this.method = "GET"
      this.endpoint = "/api/account"
    }
  }
}

