import { Decimal, Klass } from "$vue/lib/transforms"
import Big from "big.js"

export default class AgentPointRecord {
  id: number
  agent_id: number
  @Decimal() point: Big
  content: string
  @Klass(Date) created_at: Date
}
