export default class Courseware {
  id: number
  code: string
  estimate_time: number
  name: string
  tag_names: string[]
  teacher_badges: string[]
  grouping: string | null
  grouping_order: string | null
}
