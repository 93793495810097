import { Transform, Type } from "class-transformer"
import Big from 'big.js'

export function Boolean() {
  return Transform(({ value }) => {
    if (value === null || value === "" || value === undefined) {
      return null
    }

    return [1, "1", "true", "TRUE", "True"].includes(value)
  })
}

export function Decimal() {
  return Transform(({ value }) => {
    if (value == null || value == "") {
      return null
    }

    return Big(value)
  })
}

export function Klass<T>(klass: new () => T) {
  return Type(() => klass)
}
