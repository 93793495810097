export default class District {
  id: number
  code: string
  name: string
  province_id: number
  province_name: string
  city_id: number
  city_name: string

  fullnames() {
    return [ this.province_name, this.city_name, this.name ]
  }
}
