import Big from 'big.js'
import { Decimal, Klass } from "$vue/lib/transforms"

export default class AgentBalanceRecord {
  id: number
  agent_id: number
  @Decimal() amount: Big
  content: string
  @Klass(Date) created_at: Date
}
