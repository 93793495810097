import { Klass } from '$vue/lib/transforms'
import Teacher from "./Teacher"

export default class Teachership {
  id: number
  name: string
  @Klass(Date) joined_at: Date
  @Klass(Date) leave_at: string
  role: string
  role_text: string
  clazz_id: number
  subject_id: number
  subject_name: string
  leaved: boolean
  @Klass(Teacher) teacher: Teacher
}
