import { Klass } from '$vue/lib/transforms'
import Unit from "./Unit"

export default class UnitModule {
  id: number
  name: string

  @Klass(Unit)
  units: Unit[]
}
