<script setup lang="ts">
import { inject, type HTMLAttributes } from 'vue'
import { cn } from '../utils'
import { PresetKey } from './types'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()

const preset = inject(PresetKey)!
</script>

<template>
  <div :class="cn(preset.content, props.class)">
    <slot></slot>
  </div>
</template>
