import _ from "lodash"
import { type AxiosResponse } from "axios"

export default class Pagination<T> {
  list!: T[]
  total_count!: number
  offset!: number
  limit!: number

  build({ headers }: AxiosResponse) {
    this.offset = _.toInteger(headers['x-pagination-offset'])
    this.limit = _.toInteger(headers['x-pagination-limit'])
    this.total_count = _.toInteger(headers['x-pagination-total-count'])
  }

  currentPage() {
    return Math.floor(this.offset / this.limit) + 1
  }
}
