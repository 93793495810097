import { Klass } from '$vue/lib/transforms'
import EvaluationCoursewareRelation from "./EvaluationCoursewareRelation"

export default class BatchAssignHomework {
  id: number
  state: string
  target_type: string
  teacher_name: string
  teacher_id: number
  remark: string
  evaluation_courseware_relation_ids: number[]

  @Klass(Date)
  deadline_at: Date

  @Klass(Date)
  visible_at: Date

  @Klass(Date)
  published_at: Date

  @Klass(EvaluationCoursewareRelation)
  joint_evaluation_courseware_relations: EvaluationCoursewareRelation[]
  targets_by_school: Object
}
