import { Klass } from "$vue/lib/transforms"
import Textbook from "./Textbook"
import Schoolbook from "./Schoolbook"

export default class TextbookForm extends Textbook {
  folder_name: string
  folder_remark: string
  prompt_enabled: boolean
  description: string
  subject_id: number
  factions: string[]

  @Klass(Schoolbook) schoolbooks: Schoolbook[]
}
