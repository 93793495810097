<script setup lang="ts">
import { cva, type VariantProps } from 'class-variance-authority'
import { computed, provide, type HTMLAttributes } from 'vue'
import { cn } from '../utils'
import { type CardPreset, PresetKey } from './types'

type PresetId = 'standard' | 'table'

const CardPresets = {
  standard: {
    root: 'rounded-md flex flex-col border bg-card text-card-foreground shadow-sm',
    header: 'flex flex-col gap-y-1.5 p-6 py-4 border-b',
    content: 'p-6 py-5 grow',
    footer: 'flex items-center p-6 py-5',
  },

  table: {
    root: 'rounded-md border bg-card text-card-foreground shadow-sm',
    header: 'flex flex-col gap-y-1.5 p-6 py-4 border-b',
    content: 'py-2 px-4',
    footer: 'flex items-center p-6 py-5',
  },
} satisfies Record<PresetId, CardPreset>

const CardVariances = {
  standard: null,
  table: null,
} satisfies Record<PresetId, any>

type CardVariancesType = typeof CardVariances
type CardVariantsTypes = { [P in keyof CardVariancesType]: VariantProps<NonNullable<CardVariancesType[P]>> }

interface Props {
  class?: HTMLAttributes['class']
  preset?: PresetId
}

const props = withDefaults(defineProps<Props>(), {
  preset: 'standard'
})

const preset = computed(() => CardPresets[props.preset])
provide(PresetKey, preset)

const variance = computed<Function | null>(() => CardVariances[props.preset])
</script>

<template>
  <div :class="cn(preset.root, variance?.({}), props.class)" style="--color-tint: var(--primary)">
    <slot></slot>
  </div>
</template>
