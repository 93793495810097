<template>
  <Checkbox v-model:checked="checked"></Checkbox>
</template>

<script setup lang="ts">
import Checkbox from './Checkbox.vue'
import { computed } from 'vue'

const model_value = defineModel<any[]>({ required: true })
const props = defineProps<{
  value: any
}>()

const checked = computed(
  {
    get: () => model_value.value.includes(props.value),
    set: (value: boolean) => {
      if (value) {
        model_value.value.push(props.value)
      } else {
        model_value.value = model_value.value.filter((v) => v !== props.value)
      }
    }
  }
)
</script>
