import { Klass } from '$vue/lib/transforms'
import Clazz from './Clazz'
import School from './School'

export default class OrderStat {
  @Klass(School) school: School
  @Klass(Clazz) clazz: Clazz

  paid_count: number
}


