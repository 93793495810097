<template>
  <Checkbox v-model:checked="checked" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import _ from 'lodash'
import Checkbox from './Checkbox.vue'

const props = defineProps<{
  collection: number[]
}>()

const modelValue = defineModel<number[]>({ required: true })

const checked = computed({
  get: () => {
    if (isIndeterminate.value) {
      return 'indeterminate'
    } else {
      return isChecked.value
    }
  },
  set: (value: boolean | 'indeterminate') => {
    if (value) {
      modelValue.value = _.union(modelValue.value, props.collection)
    } else {
      modelValue.value = _.difference(modelValue.value, props.collection)
    }
  },
})

const isChecked = computed(() => {
  return props.collection.length > 0 && _.difference(props.collection, modelValue.value).length == 0
})

const isIndeterminate = computed(() => {
  return modelValue.value.length > 0 && _.difference(props.collection, modelValue.value).length > 0
})
</script>
