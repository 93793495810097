import { Klass } from '$vue/lib/transforms'
import Courseware from "./Courseware"
import Schoolbook from "./Schoolbook"

export default class EvaluationCoursewareRelation {
  id: number
  exam_time: number

  @Klass(Courseware) courseware: Courseware
  @Klass(Schoolbook) schoolbook: Schoolbook
}
